var idleTime = 0;
var timerValue;
var checkInterval;
var isTimeout = false;
$(document).ready(
		function() {
			$('.search-list-page-left-refinements-component .facet:not(#PriceRange, #sort-filterModal #sortPlpCategoryFacet)').click(function() {
				$('.search-list-page-left-refinements-component .facet').removeAttr('tabIndex');
				$(this).toggleClass('active');
				if ($(window).width() <= 768) {
					var sortFilter = $('#sort-filterModal');
					var scrollTopsection = parseInt(sortFilter.scrollTop());
					if($(this).hasClass('active')) {
						var increment_value = scrollTopsection + parseInt($(this).height()+100);
						sortFilter.scrollTop(increment_value);
					}
					else {
						var increment_value = scrollTopsection - parseInt($(this).height());
						sortFilter.scrollTop(increment_value);
					}
				}
				else {
					var sortFilter = $('.sort-filter-common-left-wrapper.left-nav');
					var scrollTopsection = parseInt(sortFilter.scrollTop());
					if($(this).children('button').hasClass('active')) {
						var count = $(this).nextAll('div').height() * $(this).nextAll('div').length;
						var increment_value = scrollTopsection + parseInt($(this).height()) + count;
						sortFilter.scrollTop(increment_value);
					}
					else {
						var count = $(this).prevAll('div').height() * $(this).prevAll('div').length;
						var increment_value = scrollTopsection - parseInt($(this).height()) - count;
						sortFilter.scrollTop(increment_value);
					}
				}
				$(this).attr('tabIndex',1);
				$(this).focus();
			});
			if ($("body").hasClass("page-racStaticPage")) {
				$(".addClassACIVE:eq(0)").addClass("activeClass");
			}
			if ($("body").hasClass("page-racValueHistoryStaticPage")) {
				$(".addClassACIVE:eq(1)").addClass("activeClass");
			}
			if ($("body").hasClass("page-racBrandsStaticPage")) {
				$(".addClassACIVE:eq(2)").addClass("activeClass");
			}
			if ($("body").hasClass("page-how-rac-works")) {
				$(".addClassACIVE:eq(0)").addClass("activeClass");
			}
			if ($("body").hasClass("page-racBenefits")) {
				$(".addClassACIVE:eq(1)").addClass("activeClass");
			}
			if ($("body").hasClass("page-racWhyRent")) {
				$(".addClassACIVE:eq(2)").addClass("activeClass");
			}
			if ($(".smartphoneNavigation").hasClass("smartphoneNavigation")) {
				$(".addClassACIVE:eq(0)").addClass("activeClass");
			}
			if ($("body").hasClass("page-RACComparePhonesPage")) {
				$(".addClassACIVE:eq(1)").addClass("activeClass");
			}
			if ($("body").hasClass("page-RACComparePlansPage")) {
				$(".addClassACIVE:eq(2)").addClass("activeClass");
			}
			if ($("body").hasClass("page-LaptopsSubCategoryPage")) {
				$("p.captionDescription ").removeClass("hidden-xs");
			}
			if ($("body").hasClass("page-SmartphonesSubCategoryPage")) {
				$("p.captionDescription ").removeClass("hidden-xs");
			}
			$(".brandsContent:eq(1)").addClass("margin_minus");
			$("#expandContent-2").find(".nationalLinks").hide();
			$("#expandContent-0").find(".compareContentHeading").css(
					"font-weight", "normal");
			$("#expandContent-network2").find("img:last-child").removeClass(
					"marginB20");
			;
		});

$(document)
		.ready(
				function() {
					$("li.history:nth-child(n+6)").hide();

					$(".readMore").show();
					$(".readLess").hide();
					$('#contactUsSuccess').on('hidden.bs.modal', function() {
						location.reload();
					});
				});
/* }; */
$(document)
		.ready(
				function() {
					$(".readMore").click(function() {
						$("li.history:nth-child(n+6)").show();
						$(".readMore").hide();
						$(".readLess").show();

					});

					$(".readLess").click(function() {
						$("li.history:nth-child(n+6)").hide();
						$("#focusHistory").focus();
						var pos = $("#focusHistory").offset().top;
						$('body, html').animate({
							scrollTop : pos
						});
						$(".readLess").hide();
						$(".readMore").show();
					});
					$('#storeLocaterModal').on('shown.bs.modal', function() {
						$('body, html').animate({
							scrollTop : 0
						});
					});
					
					$(".netParent .accordionHead").click(function() {
						$(".focusPlan").focus();
						var pos = $(".focusPlan").offset().top;
						$('body, html').animate({
							scrollTop : pos
						});
					});

					$(".readMoreCare").click(function() {
						$(this).parent().siblings(".careShowDesc").children().show();
						$(this).hide();
					});
					$(".readLessCare").click(function() {
						$(this).siblings(".careShowMore").hide();
						$(this).hide();
						$("#focusContactUs").focus();
						var pos = $("#focusContactUs").offset().top;
						// var pos = $("li.history:nth-child(1)").offset().top;
						// animated top scrolling
						$('body, html').animate({
							scrollTop : pos
						});
						$(this).parent().siblings(".rac-cares-icon-text").children(".readMoreCare").show();
					});
					
					$(".company-facet-btn").click(function() {
						$(".company-facets").toggle();
						$(this).find('i').toggleClass('fa-plus fa-minus');
					});
					
					$(".breadcrumbStyle").find(".breadcrumbArrow:last-child")
							.find("span").removeClass("fa-caret-right");

					$(".page-racBrandsStaticPage .twice:eq(1)").hide();
					$(".divide:eq(4)").hide();
				});

$(document).ready(function() {
	if ($(window).width() <= 991) {
		if ($("body").hasClass("page-racStaticPage")) {
			$(".addClassACIVE:eq(3)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racValueHistoryStaticPage")) {
			$(".addClassACIVE:eq(4)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racBrandsStaticPage")) {
			$(".addClassACIVE:eq(5)").addClass("activeClass");
		}
		if ($("body").hasClass("page-how-rac-works")) {
			$(".addClassACIVE:eq(3)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racBenefits")) {
			$(".addClassACIVE:eq(4)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racWhyRent")) {
			$(".addClassACIVE:eq(5)").addClass("activeClass");
		}
	}

	if (window.innerHeight < window.innerWidth) {
		if ($("body").hasClass("page-racStaticPage")) {
			$(".addClassACIVE:eq(3)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racValueHistoryStaticPage")) {
			$(".addClassACIVE:eq(4)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racBrandsStaticPage")) {
			$(".addClassACIVE:eq(5)").addClass("activeClass");
		}
		if ($("body").hasClass("page-how-rac-works")) {
			$(".addClassACIVE:eq(3)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racBenefits")) {
			$(".addClassACIVE:eq(4)").addClass("activeClass");
		}
		if ($("body").hasClass("page-racWhyRent")) {
			$(".addClassACIVE:eq(5)").addClass("activeClass");
		}
		if ($(".smartphoneNavigation").hasClass("smartphoneNavigation")) {
			$(".addClassACIVE:eq(0)").addClass("activeClass");
		}
	}
});

$(".faqPosition")
		.click(
				function() {
					$(this).find(".pos-relative").find("i").toggleClass(
							"fa-plus fa-minus");
					// $(this).siblings(".faqPosition").find("#content1").find("i").removeClass("fa-minus").addClass("fa-plus");
					$(this).closest(".faqHead").siblings(".faqHead").children(
							".faqPosition").find("i").removeClass("fa-minus")
							.addClass("fa-plus");
					$(this).closest(".faqHead").siblings(".faqHead").siblings(
							".collapse.in").collapse("hide");
				});
$(document)
		.ready(
				function() {
					/*
					 * $(".line-image").eq(5).hide();
					 * $(".line-image").eq(2).hide();
					 */
					$("#comments").css("color", "#9b9b9b");
					$(".overViewContent").eq(8).find("span").css(
							"padding-left", "9px");
					$("#comments")
							.click(
									function() {
										if ($("#comments").val() === $('#agreementPlaceholder').val()) {
											$("#comments").val('');

										}
									});
				});

var $myGroup = $('#faq2');
$myGroup.on('show.bs.collapse', '.collapse', function() {
	$myGroup.find('.collapse.in').collapse('hide');
});

$(document).ready(function() {
	const location = window.location.href;

	if ($("body").hasClass("page-racShopWithUsPage")) {
		$("#child-0").show();
	} else if ($("body").hasClass("page-racAccountPaymentsPage")) {
		$("#child-1").show();
	} else if ($("body").hasClass("page-racOwnershipPage")) {
		$("#child-2").show();
	} else if ($("body").hasClass("page-racProductServicePage")) {
		$("#child-3").show();
	} else if ($("body").hasClass("page-racBenefitsLDWPage")) {
		$("#child-4").show();
	} else if ($("body").hasClass("page-racGeneralInfoPage")) {
		$("#child-5").show();
	} else if ($("body").hasClass("page-racExchangeFAQPage")) {
		$("#child-6").show();
	} else {
		$("#child-0").show();
	}

	$("#heading-0").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/shop-with-us";
	});
	$("#heading-1").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/accounts-and-payments";
	});
	$("#heading-2").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/ownership";
	});
	$("#heading-3").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/products-and-services";
	});
	$("#heading-4").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/benefits-and-ldw";
	});
	$("#heading-5").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/general";
	});
	$("#heading-6").click(function() {
		window.location.href = ACC.config.encodedContextPath + "/faqs/rac-exchange";
	});
});
$(document).ready(function() {
	if ($(window).width() > 991) {
		if ($("body").hasClass("page-racContactUsPage")) {
			$(".childrenCollapse:eq(0)").css("display", "none");
		}
	}
});

$(document).ready(function() {
	setInterval(timerIncrement, 60000); // 1 minute
});

// Generic function to make an AJAX call
function sessionTimeoutCall() {
	idleTime = 0;
	// Return the $.ajax promise
	return $.ajax({
		type : "GET",
		url : '/myaccount/refresh'
	});
}
function timerIncrement() {
	if ((document.getElementById('customerAgreementName').value)) {
		idleTime = idleTime + 1;
		if (idleTime >= 10) { // 10 minutes
			$("#timeOutModal").modal();
		}
		if (idleTime >= 11) {
			window.location.href = $("#header-signout-link").text();
		}
	}
}

$(document).ready(
		function() {
			if ($("#child-0").is(":visible")) {
				$("#heading-0").addClass("activeButton");
			}
			if ($("#child-1").is(":visible")) {
				$("#heading-1").addClass("activeButton");
			}
			if ($("#child-2").is(":visible")) {
				$("#heading-2").addClass("activeButton");
			}
			if ($("#child-3").is(":visible")) {
				$("#heading-3").addClass("activeButton");
			}
			if ($("#child-4").is(":visible")) {
				$("#heading-4").addClass("activeButton");
			}
			if ($("#child-5").is(":visible")) {
				$("#heading-5").addClass("activeButton");
			}
			if ($("#child-6").is(":visible")) {
				$("#heading-6").addClass("activeButton");
			}
			$("body").popover({
				selector : '[data-toggle=popover]'
			});

			$(document).on("click", ".popover .tooltip-close", function() {
				$(this).parents(".popover").popover('hide');
			});
			$(document).on("click", "[data-toggle='popover']", function() { 
				$('[data-toggle="popover"]').not(this).popover('hide');
			});
			$('body').on('click', function (e) {
				if ($(e.target).data('toggle') !== 'popover'
				&& $(e.target).parents('[data-toggle="popover"]').length === 0
				&& $(e.target).parents('.popover.in').length === 0) { 
					$('[data-toggle="popover"]').popover('hide');
				}
			});
			$('body').on('hidden.bs.popover', function(e) {
				$(e.target).data("bs.popover").inState.click = false;
			});
			$(".carousel").swipe(
					{

						swipe : function(event, direction, distance, duration,
								fingerCount, fingerData) {

							if (direction == 'left')
								$(this).carousel('next');
							if (direction == 'right')
								$(this).carousel('prev');

						},
						allowPageScroll : "vertical"

					});
		});

$(document).ready(function() {
	$("#owl-demoCategoryProducts").owlCarousel({
		autoPlay : false,
		rewindNav : true,// Set AutoPlay to 3 seconds

		items : 4,
		itemsDesktop : [ 1199, 3 ],
		itemsDesktopSmall : [ 979, 3 ],
		itemsTablet : [ 768, 1 ],
		itemsTabletSmall : [ 479, 1 ],
		itemsMobile : [ 479, 1 ],
		navigation : true,
		navigationText : [ "", "" ]

	});
});
$(document).ready(function() {

	var accordian = document.getElementsByClassName('pdp-accordian');
	if (accordian.length > 0) {
		accordian[0].click();
	}
	$(".readMoreBrand").click(function() {

		$(".readDesc").show();
		$(".readLessBrand").show();
		$(".readMoreBrand").hide();

	})
	$(".readLessBrand").click(function() {

		$(".readDesc").hide();
		$(".readLessBrand").hide();
		$(".readMoreBrand").show();

	})

	if ($("body").hasClass("page-racContactUsPage")) {
		$("#heading-0").removeClass("activeButton");
		$("#child-0").hide();
	}

	$(".alphabetHeading").click(function() {
		$(this).find("i.fas").toggleClass("fa-plus fa-minus");
	});

});

$(document).ready(
		function() {
			videoHandler();
			// Get the elements with class="column"
			var elements = document.getElementsByClassName("column");

			// Declare a loop variable
			var i;

			// List View - default set list view 
			var isListView = "active";
			if ($('.pv').val() != undefined && $('.pv').val() === 'listview') {
				for (i = 0; i < elements.length; i++) {
					elements[i].style.width = "100%";
				}
			} else {
				for (i = 0; i < elements.length; i++) {
					elements[i].style.width = "50%";
				}
			}
			// show below items after settign product view style to avoid jerk
			$(".productGrid").show();
			$(".product-list").show();
			$(".plpPagination").show();

			listView: $(document).find("#listview").click(
					function() {
						for (i = 0; i < elements.length; i++) {
							elements[i].style.width = "100%";
							//$(".categoryProducts").addClass("extraPadding");
						}
						$(".productContent").addClass("listView").removeClass("gridView");
						$(".listView").find(".img-column").addClass("col-sm-3").removeClass("col-sm-5");
						$(".listView").find(".caption").addClass("col-sm-9").removeClass("col-sm-7");
						$(".glyphicon-th-list").removeClass("gridColor").addClass("font-blue");
						$(".glyphicon-th-large").addClass("gridColor").removeClass("font-blue");
					});

			// Grid View
			gridView: $(document).find("#gridview").click(
					function() {
						for (i = 0; i < elements.length; i++) {
							elements[i].style.width = "50%";
							elements[i].style.padding = "10px !important";
						}
						$(".productContent").addClass("gridView").removeClass("listView");
						$(".gridView").find(".img-column").removeClass("col-sm-3").addClass("col-sm-5");
						$(".gridView").find(".caption").removeClass("col-sm-9").addClass("col-sm-7");
						$(".glyphicon-th-large").removeClass("gridColor").addClass("font-blue");
						$(".glyphicon-th-list").addClass("gridColor").removeClass("font-blue");
					});
				
					var timeoutComponentPresent = $("#timerVal").val();
					if (timeoutComponentPresent) {		
						timerValue = parseInt($("#timerVal").val())/60 - 1;
						setWarningTimeOutForm();
						$('#moreTimeReq ,.close_btn_timeout').on('click', function() {
							$('#applicationTimeOutVA').modal('hide');
							stopWarningTimeOut();
							setWarningTimeOutForm();
						});
					}					
					if($('.sticky-content .get-it-today').length < 1) {
					   $('.mobile-sort').addClass('sort-mob');
					}	
					else {
						$('.mobile-sort').removeClass('sort-mob');
					}
					if ($('.search-list-page-left-refinements-slot').length > 0) {
						var stickyOffset ;
						var carsolheight ;
						var newCarouselHeight ;
						var dealsbanner ;
						var plpDealsCarousel;
						var greenstrip;
						var superPLPHeightFix;
						if($('.homepage-miniButterBar').length > 0) {
							greenstrip = $('.homepage-miniButterBar').height();
						}
						else {
							greenstrip = 0;
						}
						if($('#racCarousel').length > 0) {
							carsolheight = $('#racCarousel').height() + 40 ;
						}
						else {
							carsolheight = 0;
						}
						if($('#SpecialPageRotatingImageComponent.rac__carousel').length > 0) {
							newCarouselHeight = $('#SpecialPageRotatingImageComponent.rac__carousel').innerHeight() + 40 ;
							console.log(newCarouselHeight)
						}
						else {
							newCarouselHeight = 0;
						}
						if($('.sub-category.deals-section').length > 0) {
                           dealsbanner = $('.sub-category.deals-section').innerHeight() + 80;
						}
						else {
						    dealsbanner = 0;
						}
                        if($('.plpDealsCarousel').length > 0) {
                           plpDealsCarousel = $('.plpDealsCarousel').height();
                        }
                        else {
                            plpDealsCarousel = 0;
                        }
                        // Only for SuperPLP Page
						if($('body').hasClass('page-my-items')) {
							superPLPHeightFix = $('#racCarousel').length > 0 ? ($('#racCarousel').height() + 30) : 30;
						} else {
							superPLPHeightFix = 0;
						}
						if ($('.deals-section').length > 0) {
							stickyOffset = $('#header').height() + $('#breadCrumb').height() + carsolheight + dealsbanner + greenstrip + newCarouselHeight;
							console.log(1)
						}
						else {
							var clearanceSuperCardsHeight = 0;
							if($('.clearance-super-cat-container').length > 0) {
								clearanceSuperCardsHeight = $('.clearance-super-cat-container').height() + 20;
							}
							stickyOffset = $('#header').innerHeight() + $('#breadCrumb').height() + $('.plpCarousel').height() + dealsbanner + greenstrip + superPLPHeightFix + newCarouselHeight + clearanceSuperCardsHeight;
						}						
						$(window).scroll(function(){
							
							if (($('.list-group-item:visible').length) || ($('.grid-group-item:visible').length) ) {
								var stickyOffsetremove = $('.search-list-page-right-result-list-component:visible').height() - $('.left-nav .search-list-page-left-refinements-slot:visible').height() + stickyOffset;
								var sticky = $('.left-nav .search-list-page-left-refinements-slot:visible'),
								scroll = $(window).scrollTop();
								var leftNav ;
								if ((scroll >= stickyOffset) && (scroll <= stickyOffsetremove)) {
									leftNav = $(window).height() - 20;
									leftNav =  $('.left-nav').height(leftNav);
									$(sticky).css('top','0px');
									sticky.addClass('sticky');
									$('.left-nav').addClass('top-fix');
									$(sticky).css('position','relative');
								}
								else if ((scroll >= stickyOffsetremove)) {
									leftNav = $('.search-list-page-right-result-list-component:visible').height() - 70;
									leftNav =  $('.left-nav').height(leftNav);
									var leftNavigation =  $('.search-list-page-right-result-list-component:visible').height() - $('.left-nav .search-list-page-left-refinements-slot:visible').height();
									if (($(window).width() >= 767) && (scroll > leftNavigation)) {
										var topPosition ;
											topPosition = leftNavigation - 70;
											if (topPosition > 0) {
												$(sticky).css('top',topPosition+'px');
											}
											else {
												$(sticky).css('top','0px');			
											}
											sticky.removeClass('sticky');
											$('.left-nav').removeClass('top-fix');
											$(sticky).css('position','relative');
									}
									else if (($(window).width() >= 767) && (scroll < leftNavigation) && (scroll >= stickyOffset)){
											$(sticky).css('top','0px');
											sticky.addClass('sticky');
											$('.left-nav').addClass('top-fix');
											$(sticky).css('position','relative');
									}
								    else {
                                            leftNav = $('.search-list-page-right-result-list-component:visible').height() - 70;
                                            leftNav =  $('.left-nav').height(leftNav);
                                            sticky.removeClass('sticky');
                                            $('.left-nav').removeClass('top-fix');
                                            $(sticky).css('top','0px');
								    }
								}
								else { 
									leftNav = $('.search-list-page-right-result-list-component:visible').height() - 70;
									leftNav =  $('.left-nav').height(leftNav);
									sticky.removeClass('sticky');
									$('.left-nav').removeClass('top-fix');	
									$(sticky).css('top','0px');
								}
							}
						});
					}	
		});
		
function setWarningTimeOutForm() {
  warnTimeClr = setTimeout(function() {
    $('#applicationTimeOutVA').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#thankYouModal').modal('hide');
    $('#backToRacModal').modal('hide');
    $('#applicationTimeOutVA').modal('show');
    $("#timerDuration").empty().append(60);
      var j=60;
      timerShow = setInterval(function(){
        $("#timerDuration").empty().append(j);
            if(j==0){
            	 $.ajax({
            		 url: '/invalidateSession',
            		 complete: function() {
            			 location.href="/";
            			 clearInterval(timerShow);
            		 }
            	 });
            }
              j--;  
      },1000);
  }, timerValue*60*1000); //720000ms
}
function stopWarningTimeOut() {
  clearTimeout(warnTimeClr);
  clearInterval(timerShow);
}
function modalCall(id) {
	if (id == 'link1-0') {
		$('#net10NationalCoverage').modal('show');
	} else if (id == 'link2-0') {
		$('#simplemobileInternationalCoverage').modal('show');
	} else if (id == 'link1-1') {
		//$('#net10NationalCoverage').modal('show');
		$('#simplemobileNationalCoverage').modal('show');
	} else if (id == 'link2-1') {
		$('#net10InternationalCoverage').modal('show');
	}

}
var goToTopBtn = document.getElementById("goToTop");
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
	if (goToTopBtn) {
	  if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
	    goToTopBtn.style.display = "block";
	  } else {
	    goToTopBtn.style.display = "none";
	  }
	}
}	
function goToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
function makeConfirmationRedirectURL(redirectURL,separator) {
	if ($('#productCode').length > 0 && $('#productCode').val() != '') {
		redirectURL = redirectURL + separator +'productCode=' + $('#productCode').val();
		if ($('#inventory_type').length > 0 && $('#inventory_type').val() != '') {
			redirectURL = redirectURL + '&inventoryType=' + $('#inventory_type').val();
		}
	}
	window.location.href = redirectURL;
}

function videoHandler() {
    /* Stop the video when modal is closed */
    $("#videoModal,.videoModalHome,#communityVideoModal,#videoModalCommon").on('hide.bs.modal', function () {
        $(this).find("iframe").attr('src', '');
    });
    /*
     * Assign the url back to the iframe src when modal is
     * displayed again
     */
    $("#videoModal").on(
        'show.bs.modal',
        function () {
            var howRacWorksVidUrl = $(".how-video-works a[data-target='#videoModal']").attr('data-thevideo');
            $("#racVideo").attr('src',
                howRacWorksVidUrl);
        });
    $(".videoModalHome").on(
        'show.bs.modal',
        function () {
            var howRacWorksVidUrl = $(this).prev().find("a").attr('data-thevideo');
            $(this).find("iframe").attr('src',
                howRacWorksVidUrl);
        });
    $("#communityVideoModal").on(
        'show.bs.modal',
        function () {
            var videoLink = $("a[data-target='#communityVideoModal']").attr('data-thevideo');
            $(this).find(".racCommunityVideo").attr('src',
                videoLink);
        });
    //start of TCS code merge 6th Oct
    $("#videoRACModal").on('hide.bs.modal', function () {
        $(".racMagicVideo").attr('src', '');
    });

    $(".js-common-videoPlay").on('click', function () {
        var videoLink = $(this).attr('data-thevideo');
        $("#videoModalCommon").find("iframe").attr('src',
            videoLink);
        $("#videoModalCommon").modal();
    })

    /*
     * Assign the url back to the iframe src when modal is
     * displayed again
     */
    $("#videoRACModal").on(
        'show.bs.modal',
        function () {
            var howRacWorksVidUrl = $("#magicVideo").attr("data-theVideo");
            $(".racMagicVideo").attr('src', '');
            $(this).find(".racMagicVideo").attr('src',
                howRacWorksVidUrl + "?rel=0&amp;autoplay=1");
        });
    //end of TCS code merge 6th Oct
}

$(".submitApplicationFaqs").click(function () {
    window.redirectLinkClick("/submit-application", true);
});

/*
 * START Order Online and Order In Store Adobe tracking.
 */
function orderOnLinePassValue() {
    _satellite.track("pdp_OrderOnLine");
}
function orderInStorePassValue() {
	var bundleProductInfo = "";
	if ($('.bundled-products-wrapper:visible').find('.single-product-detail-box .content-selection-box input[type="radio"]:checked').length > 1) {
	      $('.bundled-products-wrapper:visible').find('.single-product-detail-box .content-selection-box input[type="radio"]:checked').each(function(index, item) {
		      var prodCode = $(item).attr('data-product-code');
	          var inventoryType = $(item).attr('data-inventory-type');
	          var inventoryId = $(item).attr('data-inventoryid');
	          var storeNumber = $(item).attr('data-inventorystore');
	          var selectedPrConditionId = $(item).attr('data-pr-condition-id');
	          if (index > 0) {
					bundleProductInfo += "~";
				}
	          bundleProductInfo += prodCode + ',' + inventoryId + ',' + storeNumber + ',' + inventoryType + ',' + selectedPrConditionId;
	      });
	      $('#js-selected-bundle-info').val(bundleProductInfo);
	 } else {
	      $("#isBundleProduct").val(false);
	 }
    _satellite.track("pdp_OrderInStore");
}

/*
 * END Order Online and Order In Store Adobe tracking.
 */


/*Story-37918 code changes*/
$(".orderNowId").click(function () {
    _satellite.track("order_now");
});
$(".viewDetailsId").click(function () {
    _satellite.track("view_details");
});

$(document).ready(function () {
	if (RAC.coreDataLayer.hfLoggedIn === 'Yes' && (RAC.coreDataLayer.pageType === 'my_acct_link_cust'
		|| RAC.coreDataLayer.pageType === 'my_acct_my_agg')) {
		getApprovalDetails();
		setTimeout(function () {
			isTimeout = true;
		}, 60000);
		checkInterval = setInterval(function () {
			getApprovalDetails();
		}, 10000);
	}
});

function getApprovalDetails() {
	$.ajax({
		url: ACC.config.encodedContextPath + '/myaccount/check-approval',
		type: 'GET',
		dataType: 'html',
		contentType: 'application/json',
		success: function (data) {
			if (data != null && RAC.coreDataLayer.pageType !== 'my_acct_link_cust') {
				$('.my-approval-container').removeClass('hide');
				$('.my-approval-container').html(data);
			}
			if (data != null || isTimeout) {
				clearInterval(checkInterval);
			}
		}
	});
}
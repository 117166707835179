// Add payment Method - banking information validation
$(document).ready(function() {
	$("#shop_now_co_renter").on('click',function(){
		
	});

	$('input[type=checkbox]#bankAccTermsAndCond').click(function() {
		var isPrimary = $(this).closest('#newSavedPaymentMethod').length === 0;

		if (this.checked && (isPrimary ? bankPrimarydet() : bankPrimarysavedPaymentdet())) {
			$('#addBankAccountSave').removeAttr('disabled');
		} else {
			$('#addBankAccountSave').attr('disabled', 'disabled');
		}
	});

	var errorMsgsFormfirst = {
		accountNameReq : ACC.accountNameReq,
		accountNamelength : ACC.accountNameReq,
		accountNamePattern : ACC.namePatternErr,
		accountContactNumber : ACC.contactNoReq,
		accountContactNumberPattern : ACC.invalidPhonNo,
		cardNameReq : ACC.cardNameRed,
		cardNameLength : ACC.cardNameRed,
		cardFirstNameReq : ACC.cardFirstNameRed,
		cardLastNameReq : ACC.cardLastNameRed,
		cardNamePattern : ACC.namePatternErr,
		billingaddr : ACC.billingAddressReq,
		billingaddrPattern : ACC.billingAddressPatternErr,
		billingAptPattern : ACC.apartmentNoPatternErr,
		cityReq : ACC.cityReq,
		cityPattern : ACC.cityPatternErr,
		zipcodeReq : ACC.zipcodeReq,
		zipcodePattern : ACC.zipcodePatternErr,
		stateReq : ACC.stateReq,
		RoutingNumberReq : ACC.bankRoutingNoReq,
		RoutingNumberPattern : ACC.routingNoLengthErr,
		AccountNumberReq : ACC.accountNoReq,
		AccountNumberPattern : ACC.accountNoLengthErr,
		AccountNumberConfirmReq : ACC.accountNoConfirmReq,
		AccountNumberConfirmEqual : ACC.accountNoMatchErr,
		accountTypeReq : ACC.accountTypeReq,
		bankFirstNameReq : ACC.bankFirstNameReq,
		bankLastNameReq : ACC.bankLastNameReq
	    
	}
	
	$("#bankAccountNumber").on('blur',function(){
		var enterLength=$("#bankAccountNumber").val().length;
		var bankAcNo = new Array(enterLength + 1).join('●');
		$("#bankAccountNumberHide").val(bankAcNo);
		$(this).hide();
		$("#bankAccountNumberHide").show();
	});
	$("#bankAccountNumberHide").on('focus',function(){
		$(this).hide();
		$("#bankAccountNumber").show();
		$("#bankAccountNumber").focus();
	});
	
	
	$("#bankAccountNumberConfirmation").on('blur',function(){
		var enterLength=$("#bankAccountNumberConfirmation").val().length;
		var bankAcNoConfirm = new Array(enterLength + 1).join('●');
		$("#bankAccountNumberConfirmationHide").val(bankAcNoConfirm);
		$(this).hide();
		$("#bankAccountNumberConfirmationHide").show();
	  	});
	$("#bankAccountNumberConfirmationHide").on('focus',function(){
		$(this).hide();
		$("#bankAccountNumberConfirmation").show();
		$("#bankAccountNumberConfirmation").focus();
	    });
	

	//To be done at Hybris
	$("#bankAccState option").filter(function(){return $.trim($(this).text()) ==  'State'}).remove();
	$("#nameAddrselectState option").filter(function(){return $.trim($(this).text()) ==  'State'}).remove();
	$('#bankAccState').selectpicker('refresh');
	$('#nameAddrselectState').selectpicker('refresh');
	
	$('#bankAccContactNumber').inputmask({
	    mask: '(999) 999-9999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});
	$('#bankAccZipcode').inputmask({
	    mask: '99999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});
	$('#nameAddrZipcode').inputmask({
	    mask: '99999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});
	$('#bankAccountNumber').inputmask({
	    mask: '99999999999999999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});
	$('#bankAccountNumberConfirmation').inputmask({
	    mask: '99999999999999999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});
	$('#bankAccRoutingNumber').inputmask({
	    mask: '999999999',
	    placeholder: ' ',
	    showMaskOnHover: false,
	    showMaskOnFocus: false,
	    positionCaretOnClick: 'none',
	    removeMaskOnSubmit: true,
	    jitMasking: true
	});

	if( $('#bankAccNameOnAccount').length ) {
		$("#bankAccNameOnAccount").parsley({
			trigger : null,
			errorsContainer : ".errorMsgAccountName",
		   required : true,
		   length : "[2,50]",
		   pattern: "/^[A-Za-z' -]*$/",
		   requiredMessage : errorMsgsFormfirst.accountNameReq,
		   patternMessage: errorMsgsFormfirst.accountNamePattern,
		   lengthMessage : errorMsgsFormfirst.accountNamelength
	   });
	}
	if ($('#firstNameOnCard').length) {
		$("#firstNameOnCard").parsley({
			trigger : null,
			errorsContainer : ".errorFirstName",
			required : true,
			length : "[2,50]",
			pattern: "/^[A-Za-z' -]*$/",
			requiredMessage : errorMsgsFormfirst.cardFirstNameReq,
			patternMessage: errorMsgsFormfirst.cardNamePattern,
			lengthMessage : errorMsgsFormfirst.cardNameLength
		});
	}
	if ($('#lastNameOnCard').length) {
		$("#lastNameOnCard").parsley({
			trigger : null,
			errorsContainer : ".errorLastName",
			required : true,
			length : "[2,50]",
			pattern: "/^[A-Za-z' -]*$/",
			requiredMessage : errorMsgsFormfirst.cardLastNameReq,
			patternMessage: errorMsgsFormfirst.cardNamePattern,
			lengthMessage : errorMsgsFormfirst.cardNameLength
		});
	}
	if ($('#nameAddrNameOnCard').length) {
		$("#nameAddrNameOnCard").parsley({
			trigger : null,
			errorsContainer : ".errorAccountName",
		   required : true,
		   length : "[2,50]",
		   pattern: "/^[A-Za-z' -]*$/",
		   requiredMessage : errorMsgsFormfirst.cardNameReq,
		   patternMessage: errorMsgsFormfirst.cardNamePattern,
		   lengthMessage : errorMsgsFormfirst.cardNameLength
	   });
	}
	if ($('#firstNameOnCard').length) {
		$("#firstNameOnCard").parsley({
			trigger : null,
			errorsContainer : ".errorFirstName",
		   required : true,
		   length : "[2,50]",
		   pattern: "/^[A-Za-z' -]*$/",
		   requiredMessage : errorMsgsFormfirst.cardFirstNameReq,
		   patternMessage: errorMsgsFormfirst.cardNamePattern,
		   lengthMessage : errorMsgsFormfirst.cardNameLength
	   });
	}
	if ($('#lastNameOnCard').length) {
		$("#lastNameOnCard").parsley({
			trigger : null,
			errorsContainer : ".errorLastName",
		   required : true,
		   length : "[2,50]",
		   pattern: "/^[A-Za-z' -]*$/",
		   requiredMessage : errorMsgsFormfirst.cardLastNameReq,
		   patternMessage: errorMsgsFormfirst.cardNamePattern,
		   lengthMessage : errorMsgsFormfirst.cardNameLength
	   });
	}
	if( $('#bankAccContactNumber').length ) {
		$("#bankAccContactNumber").parsley({
			trigger : null,
			errorsContainer : ".errorMsgContactNumber",
		   required : true,
		   length : "[14,14]",
		   pattern : "/^[(]{1}[2-9]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{3}-[0-9]{4}$/",
		   requiredMessage : errorMsgsFormfirst.accountContactNumber,
		   patternMessage: errorMsgsFormfirst.accountContactNumberPattern,
		   minlengthMessage : errorMsgsFormfirst.accountContactNumberPattern
		   
	   });
	}
	let billingAddrMaxLength =$("#addressMaxLength").val();
	if( $('#bankAccBillingAddress').length ) {
		$("#bankAccBillingAddress").parsley({
			trigger : null,
			errorsContainer : ".errorMsgBillingAddr",
		   required : true,
		   length : "[2," +billingAddrMaxLength +"]",
		   pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
		   requiredMessage : errorMsgsFormfirst.billingaddr,
		   patternMessage: errorMsgsFormfirst.billingaddrPattern,
		   lengthMessage : errorMsgsFormfirst.billingaddrPattern
	   });
	}
	if( $('#nameAddrBillingAddress').length ) {
		$("#nameAddrBillingAddress").parsley({
			trigger : null,
			errorsContainer : ".errorBillingAddr",
		   required : true,
		   length : "[2," +billingAddrMaxLength +"]",
		   pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
		   requiredMessage : errorMsgsFormfirst.billingaddr,
		   patternMessage: errorMsgsFormfirst.billingaddrPattern,
		   lengthMessage : errorMsgsFormfirst.billingaddrPattern
	   });
	}
	if($('#bankAccNameOnAccount').length) {
	 $("#bankAccNameOnAccount").parsley({
	 	trigger : null,
	 	errorsContainer : ".errorMsgAccountName",
		required : true,
		length : "[2,50]",
		pattern: "/^[A-Za-z' -]*$/",
		requiredMessage : errorMsgsFormfirst.accountNameReq,
		patternMessage: errorMsgsFormfirst.accountNamePattern,
		lengthMessage : errorMsgsFormfirst.accountNamelength
    });
    }
	if ($('#bankAccFirstName').length) {
		$("#bankAccFirstName").parsley({
			trigger : null,
			errorsContainer : ".errorAccountFirstName",
			required : true,
			length : "[2,50]",
			pattern: "/^[A-Za-z' -]*$/",
			requiredMessage : errorMsgsFormfirst.bankFirstNameReq,
			patternMessage: errorMsgsFormfirst.accountNamePattern,
			lengthMessage : errorMsgsFormfirst.accountNamelength
		});
	}
	if ($('#bankAccLastName').length) {
		$("#bankAccLastName").parsley({
			trigger : null,
			errorsContainer : ".errorAccountLastName",
			required : true,
			length : "[2,50]",
			pattern: "/^[A-Za-z' -]*$/",
			requiredMessage : errorMsgsFormfirst.bankLastNameReq,
			patternMessage: errorMsgsFormfirst.accountNamePattern,
			lengthMessage : errorMsgsFormfirst.accountNamelength
		});
	}
    if($('#nameAddrNameOnCard').length) {
	 $("#nameAddrNameOnCard").parsley({
	 	trigger : null,
	 	errorsContainer : ".errorAccountName",
		required : true,
		length : "[2,50]",
		pattern: "/^[A-Za-z' -]*$/",
		requiredMessage : errorMsgsFormfirst.cardNameReq,
		patternMessage: errorMsgsFormfirst.cardNamePattern,
		lengthMessage : errorMsgsFormfirst.cardNameLength
    });
    }
    if($("#bankAccContactNumber").length ) {
	$("#bankAccContactNumber").parsley({
	 	trigger : null,
	 	errorsContainer : ".errorMsgContactNumber",
		required : true,
		length : "[14,14]",
		pattern : "/^[(]{1}[2-9]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{3}-[0-9]{4}$/",
		requiredMessage : errorMsgsFormfirst.accountContactNumber,
		patternMessage: errorMsgsFormfirst.accountContactNumberPattern,
		minlengthMessage : errorMsgsFormfirst.accountContactNumberPattern
		
    });
    }
    if($("#bankAccBillingAddress").length ) {
    $("#bankAccBillingAddress").parsley({
	 	trigger : null,
	 	errorsContainer : ".errorMsgBillingAddr",
		required : true,
		length : "[2," +billingAddrMaxLength +"]",
		pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:;\u00C0-\u017F ']*$/",
		requiredMessage : errorMsgsFormfirst.billingaddr,
		patternMessage: errorMsgsFormfirst.billingaddrPattern,
		lengthMessage : errorMsgsFormfirst.billingaddrPattern
    });
    }
    if($("#nameAddrBillingAddress").length ) {
     $("#nameAddrBillingAddress").parsley({
	 	trigger : null,
	 	errorsContainer : ".errorBillingAddr",
		required : true,
		length : "[2," +billingAddrMaxLength +"]",
		pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:;\u00C0-\u017F ']*$/",
		requiredMessage : errorMsgsFormfirst.billingaddr,
		patternMessage: errorMsgsFormfirst.billingaddrPattern,
		lengthMessage : errorMsgsFormfirst.billingaddrPattern
    }); 
    }
	if( $('#bankAccState').length ) {
		$("#bankAccState").parsley({
			trigger : null,
			required : true,
			errorsContainer : ".errorMsgState",
			requiredMessage : errorMsgsFormfirst.stateReq
		});
	}
	if( $('#nameAddrselectState').length ) {
		$("#nameAddrselectState").parsley({
			trigger : null,
			required : true,
			errorsContainer : ".errorState",
			requiredMessage : errorMsgsFormfirst.stateReq
		});
	}
	if( $('#bankAccSelectAccType').length ) {
		$("#bankAccSelectAccType").parsley({
			trigger : null,
			required : true,
			errorsContainer : ".errorMsgSelectAccType",
			requiredMessage : errorMsgsFormfirst.accountTypeReq
		});
	}
	
	if( $('#bankAccZipcode').length ) {
		var bankAccZipcodeParsley=$("#bankAccZipcode").parsley({
			trigger : null,
			required : true,
			length : "[5,5]",		
			type : "digits",
			errorsContainer : ".errorMsgZipCode",
			requiredMessage : errorMsgsFormfirst.zipcodeReq,
			typeMessage : errorMsgsFormfirst.zipcodePattern,
		});
		bankAccZipcodeParsley.options.lengthMessage = "Please enter your 5 digit ZIP code";
	}
	
	if( $('#nameAddrZipcode').length ) {
		var ccAccZipcodeParsley=$("#nameAddrZipcode").parsley({
			trigger : null,
			required : true,
			length : "[5,5]",		
			type : "digits",
			errorsContainer : ".errorZipCode",
			requiredMessage : errorMsgsFormfirst.zipcodeReq,
			typeMessage : errorMsgsFormfirst.zipcodePattern,
		});
		ccAccZipcodeParsley.options.lengthMessage = "Please enter your 5 digit ZIP code";
	}
	if( $('#bankAccCity').length ) {
		$("#bankAccCity").parsley({
			trigger : null,
			required : true,
			length : "[2,20]",
			pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
			errorsContainer : ".errorMsgCity",
			requiredMessage : errorMsgsFormfirst.cityReq,
			patternMessage: errorMsgsFormfirst.cityPattern,
			lengthMessage : errorMsgsFormfirst.cityPattern
		});
	}
	if( $('#nameAddrCity').length ) {
		$("#nameAddrCity").parsley({
			trigger : null,
			required : true,
			length : "[2,20]",
			pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
			errorsContainer : ".errorCity",
			requiredMessage : errorMsgsFormfirst.cityReq,
			patternMessage: errorMsgsFormfirst.cityPattern,
			lengthMessage : errorMsgsFormfirst.cityPattern
		});
	}

	if( $('#bankAccRoutingNumber').length ) {
		$("#bankAccRoutingNumber").parsley({
			trigger : null,
			required : true,
			type : "digits",
			length : "[9,9]",
			errorsContainer : ".errorMsgBankRoutingNumber",
			requiredMessage : errorMsgsFormfirst.RoutingNumberReq,
			typeMessage : errorMsgsFormfirst.RoutingNumberPattern,
			lengthMessage : errorMsgsFormfirst.RoutingNumberPattern
		});
	}
	
	if( $('#bankAccountNumber').length ) {
		$("#bankAccountNumber").parsley({
			trigger : null,
			required : true,
			type : "digits",
			length : "[4,17]",
			errorsContainer : ".errorMsgAccountNumber",
			requiredMessage : errorMsgsFormfirst.AccountNumberReq,
			typeMessage : errorMsgsFormfirst.AccountNumberPattern,
			lengthMessage : errorMsgsFormfirst.AccountNumberPattern
		});
	}
	if( $('#bankAccountNumberConfirmation').length ) {
		$("#bankAccountNumberConfirmation").parsley({
			trigger : null,
			required : true,
			errorsContainer : ".errorMsgAccountNumberConfirmation",
			equalto : "#bankAccountNumber",
			requiredMessage : errorMsgsFormfirst.AccountNumberConfirmReq,
			equaltoMessage : errorMsgsFormfirst.AccountNumberConfirmEqual
		});
	}
	$('select#bankAccSelectAccType').on('changed.bs.select', function (e) {
		if($('select#bankAccSelectAccType').val()){
			$("*[data-id='bankAccSelectAccType']").removeClass("border-red");
			$(".errorMsgSelectAccType ul").css("display", "none");
		}
		else{
			$("*[data-id='bankAccSelectAccType']").addClass("border-red");
			$(".errorMsgSelectAccType ul").css("display", "block");
		}
	});

	$('select#bankAccState').on('changed.bs.select', function (e) {
		if($('select#bankAccState').val()){
			$("*[data-id='bankAccState']").removeClass("border-red");
			$(".errorMsgState ul").css("display", "none");
		}
		else{
			$("*[data-id='bankAccState']").addClass("border-red");
			$(".errorMsgState ul").css("display", "block");
		}
	});


	function validateAndSubmitBankDetails(elem) {
		var bankAccFirstNameInstance = $('#bankAccFirstName').parsley(),
			bankAccLastNameInstance = $('#bankAccLastName').parsley(),
			contactNumberInstance = $('#bankAccContactNumber').parsley(),
			billingAddressInstance = $('#bankAccBillingAddress').parsley(),
			cityInstance = $('#bankAccCity').parsley(),
			selectStateInstance = $('#bankAccState').parsley(),
			zipCodeInstance = $('#bankAccZipcode').parsley(),
			bankAccountNumberInstance = $('#bankAccRoutingNumber').parsley(),
			bankRoutingNumberInstance = $('#bankAccountNumber').parsley();
			

		bankAccFirstNameInstance.validate();
		bankAccLastNameInstance.validate();
		contactNumberInstance.validate();
		billingAddressInstance.validate();
		cityInstance.validate();
		selectStateInstance.validate();
		zipCodeInstance.validate();
		bankAccountNumberInstance.validate();
		bankRoutingNumberInstance.validate();
		selectStateInstance.isValid()?$("*[data-id='bankAccState']").removeClass("border-red"):$("*[data-id='bankAccState']").addClass("border-red");

		if (bankAccFirstNameInstance.isValid() && bankAccLastNameInstance.isValid() &&  billingAddressInstance.isValid() && cityInstance.isValid() && contactNumberInstance.isValid()/*&& bankAccountNumberConfirmInstance.isValid()*/ &&
			selectStateInstance.isValid() && zipCodeInstance.isValid() && bankAccountNumberInstance.isValid() && bankRoutingNumberInstance.isValid() /*&& selectAccTypeInstance.isValid()*/)
		{
            $("#bankAccBillingAddress, #bankAccApartment, #bankAccCity, #bankAccState, #bankAccZipcode").removeAttr('disabled');
            $("#bankAccState").selectpicker('refresh');
            elem.css('pointer-events','none');
			paymentMethods.saveCCPayment(elem, null);
		}
		else{
			$('#addBankAccountPopup1').animate({
				scrollTop: $(".has-error").offset().top - 50
			}, 1000);
		}
}
$("#bankAccNameOnAccount").blur(function() {
	 var nameOnAccountInstance = $('#bankAccNameOnAccount').parsley();
	 nameOnAccountInstance.validate();
	});
	$("#bankAccFirstName").blur(function() {
		var bankAccFirstNameInstance = $('#bankAccFirstName').parsley();
		bankAccFirstNameInstance.validate();
	});
	$("#bankAccLastName").blur(function() {
		var bankAccLastNameInstance = $('#bankAccLastName').parsley();
		bankAccLastNameInstance.validate();
	});
		$("#bankAccContactNumber").blur(function() {
	 var contactNumberInstance = $('#bankAccContactNumber').parsley();
	 contactNumberInstance.validate();
	});
		$("#bankAccRoutingNumber").blur(function() {
	 var bankAccountNumberInstance = $('#bankAccRoutingNumber').parsley();
	 bankAccountNumberInstance.validate();
	});
		$("#bankAccountNumber").blur(function() {
	 var bankRoutingNumberInstance = $('#bankAccountNumber').parsley();
	 bankRoutingNumberInstance.validate();
	});
		$("#bankAccBillingAddress").blur(function() {
	 var billingAddressInstance = $('#bankAccBillingAddress').parsley();
	 billingAddressInstance.validate();
	});
	$("#bankAccZipcode").blur(function() {
	 var zipCodeInstance = $('#bankAccZipcode').parsley();
	 zipCodeInstance.validate();
	});
	$("#bankAccCity").blur(function() {
	 var cityInstance = $('#bankAccCity').parsley();
	 cityInstance.validate();
	});	
	$(".errorTest").click(function() {
		var selectStateInstance = $('#bankAccState').parsley();
		 selectStateInstance.validate();
		 if($('select#bankAccState').val()){
				//$("*[data-id='nameAddrselectState']").removeClass("border-red");
				$(".errorTest").removeClass("has-error");
			}
		});
	function validateAndSubmitNameAddrInfo(elem){
		var nameOnAccountInstance = $('#nameAddrNameOnCard').parsley(),
			billingAddressInstance = $('#nameAddrBillingAddress').parsley(),
			//appartmentInstance = $('#nameAddrApartment').parsley(),
			cityInstance = $('#nameAddrCity').parsley(),
			stateInstance = $('#nameAddrselectState').parsley(),
			zipCodeInstance = $('#nameAddrZipcode').parsley();

		nameOnAccountInstance.validate();
		billingAddressInstance.validate();
		cityInstance.validate();
		stateInstance.validate();
		zipCodeInstance.validate();

		stateInstance.isValid()?$("*[data-id='nameAddrselectState']").removeClass("border-red"):$("*[data-id='nameAddrselectState']").addClass("border-red");
		if(nameOnAccountInstance.isValid() && billingAddressInstance.isValid() && cityInstance.isValid() && stateInstance.isValid() && zipCodeInstance.isValid()){
            $("#nameAddrBillingAddress, #nameAddrApartment, #nameAddrCity, #nameAddrselectState, #nameAddrZipcode").removeAttr('disabled');
            $("#nameAddrselectState").selectpicker('refresh');
            
			$("#payForm").submit();
			$(window).scrollTop(0);
		}
		else{
			$('#nameAddrInfoPopup').animate({
				scrollTop: $(".has-error").offset().top - 50
			}, 1000);
		}
	}
$("#nameAddrNameOnCard").blur(function() {
	 var nameOnAccountInstance = $('#nameAddrNameOnCard').parsley();
	 nameOnAccountInstance.validate();
	});
	$("#firstNameOnCard").blur(function() {
		var firstNameAccountInstance = $('#firstNameOnCard').parsley();
		 firstNameAccountInstance.validate();
	});
	$("#lastNameOnCard").blur(function() {
		var lastNameAccountInstance = $('#lastNameOnCard').parsley();
		 lastNameAccountInstance.validate();
	});
	$("#nameAddrBillingAddress").blur(function() {
	 var billingAddressInstance = $('#nameAddrBillingAddress').parsley();
	 billingAddressInstance.validate();
	});
	$("#nameAddrCity").blur(function() {
	 var cityInstance = $('#nameAddrCity').parsley();
	 cityInstance.validate();
	});
	$(".errorCCTest").click(function() {
	 var stateInstance = $('#nameAddrselectState').parsley();
	 stateInstance.validate();
	 if($('select#nameAddrselectState').val()){
			//$("*[data-id='nameAddrselectState']").removeClass("border-red");
			$(".errorCCTest").removeClass("has-error");
		}
	});
	$("#nameAddrZipcode").blur(function() {
	 var zipCodeInstance = $('#nameAddrZipcode').parsley();
	 zipCodeInstance.validate();
	});

	$("#addBankAccountSave").on('click', function(){
		validateAndSubmitBankDetails($(this));
	});

	$("#nameAddrInfoPopupSave").on('click', function(){
		validateAndSubmitNameAddrInfo($(this));
	});
	$('.modal').on('shown.bs.modal', function() { 
        $("body").scrollTop(0);
      });
      window.Parsley.on('field:error', function() {
      var field = this.$element[0].id;
      $('#' + field).parent().addClass('has-error');
      $('#' + field).parent().removeClass('parsley-error');
      $('#' + field).parent().removeClass('has-success');
      $(".has-error .successchk").removeClass('check-circle');
      if(field === 'bankAccState'){
    	  $('.errorTest').addClass('has-error');
    	  $('.errorTest').removeClass('parsley-error');
    	  $('#' + field).parent().removeClass('has-error');
      } else if(field === 'nameAddrselectState') {
    	  $('.errorCCTest').addClass('has-error');
    	  $('.errorCCTest').removeClass('parsley-error');
    	  $('#' + field).parent().removeClass('has-error');
      }
      });
      
      window.Parsley.on('field:success', function() {
      var field = this.$element[0].id;
      $('#' + field).parent().addClass('has-success');
      $('#' + field).parent().removeClass('has-error');
      $('#' + field).parent().removeClass('parsley-success');
      $(".has-success .successchk").addClass('check-circle');
      });
      $(function () {
    	  $('#personal-info-edit').on('shown.bs.modal', function() { 
     $('#demo-form').parsley().on('field:validated', function() {
      
      })
      .on('form:submit', function() {
      
      return false;
      });
    	  });
    	  $('#addBankAccountPopup1').on('shown.bs.modal', function() { 
      $('#bank-form').parsley().on('field:validated', function() {
      
      })
      .on('form:submit', function() {
      return false;
      });
    	  });
    	  $('#creditcardInfoPopup').on('shown.bs.modal', function() { 
      $('#credit-form').parsley().on('field:validated', function() {
      
      })
      .on('form:submit', function() {
      return false;
      });
    	  });
      });

      (function() {
      $('.formValid input').keyup(function() {
          var empty = false;
          $('.formValid input').each(function() {
              if ($(this).val() == '') {
            	  
                  empty = true;
              }
          });
          if (empty) {
              $('#savePersonalInfoBtn').attr('disabled', 'disabled');
          } else {
              $('#savePersonalInfoBtn').removeAttr('disabled');
          }
      });
      })();
      
      function bankDetCheck(){
      var empty = false;
      $('.bankValid input').each(function() {
              if ($(this).val() == '') {
            	  
                  empty = true;
              }
              else {
              	empty = false;
              }
          });
          return empty;
      }
      function bankPrimarydet(){
      if(($('#bankAccContactNumber').val() == '') && ($('#bankAccNameOnAccount').val() == '') && ($('#bankAccRoutingNumber').val() == '') && ($('#bankAccountNumber').val() == '')){
      return false;
      }
      else {
      return true;
      }
      }
	function bankPrimarysavedPaymentdet(){
		if (($('#bankAccFirstName').val() == '') && ($('#bankAccLastName').val() == '') && ($('#bankAccContactNumber').val() == '') && ($('#bankAccRoutingNumber').val() == '')){
			return false;
		} else {
			return true;
		}
	}
      (function() {
      $('.bankValid input').keyup(function() {
          var empty = false;
          $('.bankValid input').each(function() {
              if ($(this).val() == '') {
            	  
                  empty = true;
              } else {
                	empty = false;
              }
          });
          $('#addBankAccountPopup1 .chbBil input[type=checkbox]').click(function() {
         if( this.checked){
        	  empty = false;
        	  if(bankPrimarydet() && $('#addBankAccountPopup1 .chkvl input[type=checkbox]').prop('checked')){
        	   $('#addBankAccountSave').removeAttr('disabled');
        	  }
        	}
          });
      var emptyVal = $('#addBankAccountPopup1 .chkvl input[type=checkbox]').prop('checked');
      $('#addBankAccountPopup1 .chkvl input[type=checkbox]').click(function() {
      if (this.checked && !bankDetCheck()) {
             emptyVal = false;
          } else {
       emptyVal = true;
          }
    
      if ((emptyVal && empty) || (!emptyVal && empty) || (emptyVal && !empty)) {
    	  
              $('#addBankAccountSave').attr('disabled', 'disabled');
          } else {
        	  
              $('#addBankAccountSave').removeAttr('disabled');
          }
      });
          if ((emptyVal && empty) || (!emptyVal && empty) || (!emptyVal && !empty)) {
        	  
              $('#addBankAccountSave').attr('disabled', 'disabled');
               $('#bankAccTermsAndCond').prop('checked', false);
              
          } else {
        	 
              $('#addBankAccountSave').removeAttr('disabled');
          }
      });
      })();
      
      function creditDetCheck(){
      var empty = false;
      $('.creditValid input').each(function() {
              if ($(this).val() == '') {
                  empty = true;
              }
              else {
              	empty = false;
              }
          });
          return empty;
      }
      function creditPrimarydet(){
      if(($('#nameAddrNameOnCard').val() == '')){
      return false;
      }
      else {
      return true;
      }
      }
      (function() {
      $('.creditValid input').keyup(function() {
          var empty = false;
          $('.creditValid input').each(function() {
              if ($(this).val() == '') {
                  empty = true;
              }
          });
          $('#creditcardInfoPopup .chbBil input[type=checkbox]').click(function() {
        	  if( $('#creditcardInfoPopup .chbBil input[type=checkbox]').prop('checked')){
        	  empty = false;
        	  if(creditPrimarydet() && $('#creditcardInfoPopup .chkvl input[type=checkbox]').prop('checked')){
        	   $('#nameAddrInfoPopupSave').removeAttr('disabled');
        	   $('#submitNewCC').removeAttr('disabled');
        	  }
        	}
          });
          var emptyVal = $('#creditcardInfoPopup .chkvl input[type=checkbox]').prop('checked');
      $('#creditcardInfoPopup .chkvl input[type=checkbox]').click(function() {
      if (this.checked && !creditDetCheck()) {
             emptyVal = false;
          } else {
       emptyVal = true;
          }
      if ((emptyVal && empty) || (!emptyVal && empty) || (emptyVal && !empty)) {
              $('#nameAddrInfoPopupSave').attr('disabled', 'disabled');
              $('#submitNewCC').attr('disabled', 'disabled');
          } else {
              $('#nameAddrInfoPopupSave').removeAttr('disabled');
              $('#submitNewCC').removeAttr('disabled');
          }
      });
          if ((emptyVal && empty) || (!emptyVal && empty) || (!emptyVal && !empty)) {
              $('#nameAddrInfoPopupSave').attr('disabled', 'disabled');
              $('#submitNewCC').attr('disabled', 'disabled');
          } else {
              $('#nameAddrInfoPopupSave').removeAttr('disabled');
              $('#submitNewCC').removeAttr('disabled');
          }
      });
      })();
	  if(($('#nameAddrNameOnCard').val() != '')){
		  $('#nameAddrNameOnCard').trigger('keyup');
	  }
});

function checkPersnlInfo() {
	  var empty = false;
        $('.formValid input').each(function() {
                    if ($(this).val() == '') {
                        empty = true;
                    }
                });
                if (empty) {
                    $('#savePersonalInfoBtn').attr('disabled', 'disabled');
                } else {
                    $('#savePersonalInfoBtn').removeAttr('disabled');
                }
}

$("#submitNewCC").on('click', function(){
	let pageName = $('#page-name').val();
	if (pageName != "racPaymentMethodsPage") {
		getMyAccountPaymentOptions();
	}
	if (pageName == "racReviewAutopayPage") {
		var clonedFormInputs = $(".enrollAutopayForm").clone(true);
		$("#credit-form").append(clonedFormInputs);
	}
	validateAndSubmitNewCCInfo($(this));
});

function validateAndSubmitNewCCInfo(elem){
	var firstNameAccountInstance = $('#firstNameOnCard').parsley(),
    lastNameAccountInstance = $('#lastNameOnCard').parsley(),
	billingAddressInstance = $('#nameAddrBillingAddress').parsley(),
	cityInstance = $('#nameAddrCity').parsley(),
	stateInstance = $('#nameAddrselectState').parsley(),
	zipCodeInstance = $('#nameAddrZipcode').parsley();

    firstNameAccountInstance.validate();
    lastNameAccountInstance.validate();
	billingAddressInstance.validate();
	cityInstance.validate();
	stateInstance.validate();
	zipCodeInstance.validate();

	submitPaymentEvent();

	stateInstance.isValid()?$("*[data-id='nameAddrselectState']").removeClass("border-red"):$("*[data-id='nameAddrselectState']").addClass("border-red");
	if(firstNameAccountInstance.isValid() && lastNameAccountInstance.isValid() && billingAddressInstance.isValid() && cityInstance.isValid() && stateInstance.isValid() && zipCodeInstance.isValid()){
        $("#nameAddrBillingAddress, #nameAddrApartment, #nameAddrCity, #nameAddrselectState, #nameAddrZipcode").removeAttr('disabled');
        $("#nameAddrselectState").selectpicker('refresh');
        $("#payForm").submit();
		$(window).scrollTop(0);
	}
	else{
		$('#nameAddrInfoPopup').animate({
			scrollTop: $(".has-error").offset().top - 50
		}, 1000);
	}
}
// Track Additional Payment
function submitPaymentEvent () {
	let isPaidAgreements = $('#isPaidAgreements').data('value');
	let totalPaymentAmount = $('.total-payment-amount').text();
	if (isTrue(isPaidAgreements) && !isNullUndefined(totalPaymentAmount)) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'myaccount-make-additional-payment-submit',
			'dlPaymentAmount': totalPaymentAmount
		});
	}
}

function validateCCNamesFields() {
	let firstNameAccountInstance = $('#firstNameOnCard').parsley(),
	lastNameAccountInstance = $('#lastNameOnCard').parsley();
	if (firstNameAccountInstance.validate() && lastNameAccountInstance.validate()) {
		return true;
	} else {
		return false;
	}
}
$('#creditcardInfoPopup .chkvl input[type=checkbox]').click(function() {
	if ($('#creditcardInfoPopup .chbBil input[type=checkbox]').prop('checked')) {
		if (this.checked && isTrue(validateCCNamesFields())) {
			$('#nameAddrInfoPopupSave').removeAttr('disabled');
			$('#submitNewCC').removeAttr('disabled');
		} else {
			$('#nameAddrInfoPopupSave').attr('disabled', 'disabled');
			$('#submitNewCC').attr('disabled', 'disabled');
		}
	}
});